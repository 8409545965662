import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

import { setVhUnit } from '../../helpers'

import Layout from '../../components/Layout'
import PortfolioStart from '../../components/pages/portfolio/PortfolioStart'
import PortfolioGallery from '../../components/pages/portfolio/PortfolioGallery'
import ContactUs from '../../components/contact-us'
import Overlay from '../../components/overlay/Overlay'
import Menu from '../../components/menu'

import '../../styles/pages/portfolio/index.scss'

export default () => {
  const onOrientationChange = () => {
    setTimeout(() => setVhUnit(), 500)
  }

  useEffect(() => {
    setVhUnit()

    window.addEventListener('orientationchange', onOrientationChange)

    return () => window.removeEventListener('orientationchange', onOrientationChange)
  }, [])

  return (
    <Layout>
      <Helmet>
        <title>Zimalab | Our Work</title>
        <meta name="description" content="Zimalab released over 60 custom web products which are currently used in Education, Fintech, Music, Marketing, Security, Distribution, Auto and many other areas"/>
        <meta name="Keywords" content="Symfony developer, Symfony development team, Hire symfony developer, Hire PHP developer, Hire laravel developer, Hire php agency, Hire web agency, Hire website developer, Hire API developer, Custom website developer, Hire php freelancer, ERP developer, CRM developer, Saas developer, MVP developer, portfolio, custom web app, web product, fintech, edtech"/>
      </Helmet>
      <main className="portfolio-page">
        <PortfolioStart/>
        <PortfolioGallery/>
        <ContactUs/>
        <Overlay/>
        <Menu/>
      </main>
    </Layout>
  )
}
