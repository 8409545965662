import React from 'react'

import Gallery from '../../gallery/Gallery'

import { portfolioItems } from '../../../constants'

import '../../../styles/pages/portfolio/portfolioGallery.scss'

export default () => (
  <Gallery
    accessLarge
    {...{ portfolioItems }}
    columnsCount={ 3 }
    cardsMap={`
      2 2 2
        0
       111
      1 1 1
      2 2 2
        0
       111
      1 1 1
       111
    `}
  />
)
